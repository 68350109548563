<template>
    <div class="white component-container">

        <Spoiler v-if="cmsBlock.InSpoiler" class="form-spoiler" :title="localize(cmsBlock.SpoilerTitle) || $t('reachMemberSpoilerTitle')">
            <div v-if="formSent" class="form-message">
                <img src="@/assets/icon_success.svg" alt="check" />
                <h3>{{ $t('reachmemberFormSent') }}</h3>
                <p>{{ $t('reachmemberFormSentText') }}</p>
            </div>
            <div v-if="formFailed" class="form-message">
                <img src="@/assets/icon_error.svg" alt="error" />
                <h3>{{ $t('reachmemberFormError') }}</h3>
                <p>{{ $t('reachmemberFormErrorText') }}</p>
            </div>
            <section v-if="!formSent && !formFailed" class="white no-padding">
                <div class="form">
                    <div class="field-header">{{ $t('reachMemberPersonalData') }}*</div>
                    <div class="fieldset">
                        <SelectField v-model="prefix" :options="getOptions('prefix', 'reachmemberPrefix')"
                            :placeholder="$t('applicationSelect')" ref="prefix" />
                        <SelectField v-model="title" :options="getOptions('title', 'reachmemberTitle')"
                            :placeholder="$t('applicationSelect')" ref="title" />
                        <InputField v-model="firstname" :label="$t('applicationFirstname')" ref="firstname" />
                        <InputField v-model="lastname" :label="$t('applicationLastname')" ref="lastname" />
                    </div>

                    <div class="field-header">{{ $t('reachmemberEmail') }}*</div>
                    <div class="fieldset single">
                        <InputField v-model="email" :label="$t('applicationEmail')" ref="email" />
                    </div>

                    <div class="field-header">{{ $t('reachmemberStatusGroupAttention') }}*</div>
                    <div class="fieldset">
                        <SelectField v-model="statusgroup" :options="getOptions('statusgroup', 'reachmemberStatusgroup')"
                            :placeholder="$t('applicationSelect')" ref="statusgroup" />
                        <SelectField v-model="attention" :options="getOptions('attention', 'reachmemberAttention')"
                            :placeholder="$t('applicationSelect')" ref="attention" />
                    </div>
                    <div class="field-header">{{ $t('reachmemberInterests') }}*</div>
                    <div class="fieldset single">
                        <MultiSelectField v-model="interests"
                            :options="getMultiSelectOptionsInterests(possibleOptionsInterests, 'reachmemberInterests', cmsBlock.PreSelectedInterests)" :fullWidth="true"
                            @change="handleSelectionChangeInterests" ref="interests" />
                    </div>
                    <div class="field-header">{{ $t('reachmemberTracks') }}*</div>
                    <div class="fieldset single">
                        <MultiSelectField v-model="tracks"
                            :options="getMultiSelectOptionsTracks(possibleOptionsTracks, 'reachmemberTracks', cmsBlock.PreSelectedTracks)"
                            @change="handleSelectionChangeTracks" ref="tracks" />
                    </div>

                    <div v-if="cmsBlock.ShowMessageField" class="field-header">{{ $t('reachmemberMessage') }}*</div>
                    <div v-if="cmsBlock.ShowMessageField" class="fieldset single">
                        <TextareaField v-if="cmsBlock.ShowMessageField" v-model="message" ref="message" />
                    </div>

                    <div class="fieldset finish">
                        <div class="field privacy-policy">
                            <div class="checkbox-wrapper" ref="privacyPolicyField">
                                <label>
                                    <input ref="privacyPolicy" type="checkbox" v-model="privacyPolicyConsent" />
                                    <span class="checkmark"></span>
                                    <span v-html="$t('applicationPrivacyPolicyConsent')"></span>
                                </label>
                            </div>
                        </div>

                        <div class="centered">* {{ $t('reachmemberRequiredFields') }}</div>

                        <button class="send button-dark" v-on:click="sendReachMember">
                            {{ $t('reachmemberSend') }}
                        </button>
                    </div>
                </div>

            </section>
        </Spoiler>
        <template v-else>
            <div v-if="formSent" class="form-message">
                <img src="@/assets/icon_success.svg" alt="check" />
                <h3>{{ $t('reachmemberFormSent') }}</h3>
                <p>{{ $t('reachmemberFormSentText') }}</p>
            </div>
            <div v-if="formFailed" class="form-message">
                <img src="@/assets/icon_error.svg" alt="error" />
                <h3>{{ $t('reachmemberFormError') }}</h3>
                <p>{{ $t('reachmemberFormErrorText') }}</p>
            </div>
            <section v-if="!formSent && !formFailed" class="white no-padding">
                <div class="form">
                    <div class="field-header">{{ $t('reachMemberPersonalData') }}*</div>
                    <div class="fieldset">
                        <SelectField v-model="prefix" :options="getOptions('prefix', 'reachmemberPrefix')"
                            :placeholder="$t('applicationSelect')" ref="prefix" />
                        <SelectField v-model="title" :options="getOptions('title', 'reachmemberTitle')"
                            :placeholder="$t('applicationSelect')" ref="title" />
                        <InputField v-model="firstname" :label="$t('applicationFirstname')" ref="firstname" />
                        <InputField v-model="lastname" :label="$t('applicationLastname')" ref="lastname" />
                    </div>

                    <div class="field-header">{{ $t('reachmemberEmail') }}*</div>
                    <div class="fieldset single">
                        <InputField v-model="email" :label="$t('applicationEmail')" ref="email" />
                    </div>

                    <div class="field-header">{{ $t('reachmemberStatusGroupAttention') }}*</div>
                    <div class="fieldset">
                        <SelectField v-model="statusgroup" :options="getOptions('statusgroup', 'reachmemberStatusgroup')"
                            :placeholder="$t('applicationSelect')" ref="statusgroup" />
                        <SelectField v-model="attention" :options="getOptions('attention', 'reachmemberAttention')"
                            :placeholder="$t('applicationSelect')" ref="attention" />
                    </div>
                    <div class="field-header">{{ $t('reachmemberInterests') }}*</div>
                    <div class="fieldset single">
                        <MultiSelectField v-model="interests"
                            :options="getMultiSelectOptionsInterests(possibleOptionsInterests, 'reachmemberInterests', cmsBlock.PreSelectedInterests)" :fullWidth="true"
                            @change="handleSelectionChangeInterests" ref="interests" />
                    </div>
                    <div class="field-header">{{ $t('reachmemberTracks') }}*</div>
                    <div class="fieldset single">
                        <MultiSelectField v-model="tracks"
                            :options="getMultiSelectOptionsTracks(possibleOptionsTracks, 'reachmemberTracks', cmsBlock.PreSelectedTracks)"
                            @change="handleSelectionChangeTracks" ref="tracks" />
                    </div>

                    <div v-if="cmsBlock.ShowMessageField" class="field-header">{{ $t('reachmemberMessage') }}*</div>
                    <div v-if="cmsBlock.ShowMessageField" class="fieldset single">
                        <TextareaField v-if="cmsBlock.ShowMessageField" v-model="message" ref="message" />
                    </div>

                    <div class="fieldset finish">
                        <div class="field privacy-policy">
                            <div class="checkbox-wrapper" ref="privacyPolicyField">
                                <label>
                                    <input ref="privacyPolicy" type="checkbox" v-model="privacyPolicyConsent" />
                                    <span class="checkmark"></span>
                                    <span v-html="$t('applicationPrivacyPolicyConsent')"></span>
                                </label>
                            </div>
                        </div>

                        <div class="centered">* {{ $t('reachmemberRequiredFields') }}</div>

                        <button class="send button-dark" v-on:click="sendReachMember">
                            {{ $t('reachmemberSend') }}
                        </button>
                    </div>
                </div>

            </section>
        </template>
    </div>
</template>

<script>
import { reachMemberMutation } from '@/mutations'
import { scrollToElement } from '@/helper'

import { enumQuery } from '@/queries'

export default {
    name: 'ReachMemberForm',

    components: {
        TextBlock: () => import('@/components/PageBuilder/TextBlock'),
        Separator: () => import('@/components/PageBuilder/Separator'),
        InputField: () => import('@/components/Form/InputField'),
        TextareaField: () => import('@/components/Form/TextareaField'),
        RadioField: () => import('@/components/Form/RadioField'),
        SelectField: () => import('@/components/Form/SelectField'),
        CheckboxField: () => import('@/components/Form/CheckboxField'),
        MultiSelectField: () => import('@/components/Form/MultiSelectField'),
        Spoiler: () => import('@/components/PageBuilder/Elements/Spoiler'),
    },

    props: [
        'cmsBlock'
    ],

    data() {
        return {
            formSent: false,
            formFailed: false,

            prefix: null,
            title: null,
            firstname: null,
            lastname: null,
            email: null,

            statusgroup: null,
            attention: null,
            interests: null,
            tracks: null,
            message: null,
            privacyPolicyConsent: false,

            possibleOptionsInterests: [
                "business_cooperation",
                "co_founder_matching",
                "events",
                "founding",
                "founders",
                "funding",
                "mentoring",
                "reach_academy",
                "research",
            ],

            possibleOptionsTracks: [
                "womens_empowerment",
                "artificial_intelligence",
                "social_entrepreneurship",
                "energy",
                "health",
                "food",
                "education",
                "finance",
                "greentech",
            ],
        }
    },

    apollo: {
        prefixEnum: {
            query: enumQuery,
            variables: {
                enumName: 'ENUM_REACHMEMBER_PREFIX'
            },
            update: data => data.__type.enumValues.map(function (item) { return item.name })
        },
        titleEnum: {
            query: enumQuery,
            variables: {
                enumName: 'ENUM_REACHMEMBER_TITLE'
            },
            update: data => data.__type.enumValues.map(function (item) { return item.name })
        },
        statusgroupEnum: {
            query: enumQuery,
            variables: {
                enumName: 'ENUM_REACHMEMBER_STATUSGROUP'
            },
            update: data => data.__type.enumValues.map(function (item) { return item.name })
        },
        attentionEnum: {
            query: enumQuery,
            variables: {
                enumName: 'ENUM_REACHMEMBER_ATTENTION'
            },
            update: data => data.__type.enumValues.map(function (item) { return item.name })
        }
    },

    computed: {
        mutationVariables() {
            return {
                prefix: this.prefix,
                title: this.title,
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                statusgroup: this.statusgroup,
                attention: this.attention,
                interests: this.interests,
                tracks: this.tracks,
                message: this.message,
            }
        },

        enums() {
            return {
                prefix: this.prefixEnum,
                title: this.titleEnum,
                statusgroup: this.statusgroupEnum,
                attention: this.attentionEnum,
            }
        }
    },

    methods: {
        //TODO Options for the other multi select field
        getMultiSelectOptionsInterests(keys, i18n, preSelectedInterests) {
            var self = this
            var options = []
            keys.forEach(function (key) {
                options.push({
                    value: key,
                    label: self.$t(`${i18n}_${key}`),
                    selected: preSelectedInterests && preSelectedInterests.includes(key)
                })
            })
            return options
        },

        getMultiSelectOptionsTracks(keys, i18n, preSelectedTracks) {
            var self = this
            var options = []
            keys.forEach(function (key) {
                options.push({
                    value: key,
                    label: self.$t(`${i18n}_${key}`),
                    selected: preSelectedTracks && preSelectedTracks.includes(key)
                })
            })
            return options
        },

        handleSelectionChangeInterests(selectedKeys) {
            this.interests = selectedKeys
        },

        handleSelectionChangeTracks(selectedKeys) {
            this.tracks = selectedKeys
        },

        getOptions(key, i18n) {
            var self = this
            var options = []
            if (this.enums && this.enums[key]) {
                this.enums[key].forEach(function (option, i) {
                    options.push({
                        value: option,
                        label: self.$t(`${i18n}_${option}`)
                    })
                })
            }
            return options
        },

        validateSingle(element) {
            if (element.value && element.value.length > 0) {
                element.$el.classList.remove('invalid')
                return true
            } else {
                element.$el.classList.add('invalid')
                return false
            }
        },

        validateMail() {
            var emailEmpty = true
            var validFormat = false

            if (this.$refs.email.value && this.$refs.email.value.length > 0) {
                emailEmpty = false
            }
            if (this.$refs.email.value && this.validateEmailRegex(this.$refs.email.value)) {
                validFormat = true
            }

            if (emailEmpty || !validFormat) {
                this.$refs.email.$el.classList.add('invalid')
            }
            if (!emailEmpty && validFormat) {
                return true
            }

            return false
        },

        validateSingleMail(element) {
            if (element.value && element.value.length > 0 && this.validateEmailRegex(element.value)) {
                element.$el.classList.remove('invalid')
                return true
            } else {
                element.$el.classList.add('invalid')
                return false
            }
        },

        validateEmailRegex(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(email).toLowerCase())
        },

        validateComboField(element1, element2) {
            if (element1.value && element1.value.length > 0) {
                element1.$el.classList.remove('invalid')
                return true
            } else if (element2.value && element2.value.length > 0) {
                element1.$el.classList.remove('invalid')
                return true
            } else {
                element1.$el.classList.add('invalid')
                return false
            }
        },

        validateAll() {
            var self = this
            var scrolled = false

            var singleFields = [
                this.$refs.prefix,
                this.$refs.firstname,
                this.$refs.lastname,
                this.$refs.statusgroup,
                this.$refs.attention,
            ]
            var singleFieldsValid = true

            singleFields.forEach(function (field) {
                singleFieldsValid &= self.validateSingle(field)
                if (!singleFieldsValid && !scrolled) {
                    scrollToElement(field.$el)
                    scrolled = true
                }
            })

            var emailValid = this.validateMail()
            if (!emailValid && !scrolled) {
                scrollToElement(this.$refs.email.$el)
                scrolled = true
            }

            if (this.$refs.privacyPolicy.checked) {
                this.$refs.privacyPolicyField.classList.remove('invalid')
            } else {
                this.$refs.privacyPolicyField.classList.add('invalid')
            }

            return (singleFieldsValid &&
                emailValid &&
                this.$refs.privacyPolicy.checked)
        },

        sendReachMember() {
            if (this.validateAll()) {
                var self = this
                this.$apollo.mutate({
                    mutation: reachMemberMutation,
                    variables: self.mutationVariables
                }).then(function (data) {
                    self.formSent = true
                }).catch(function (err) {
                    self.formFailed = true
                    console.log(err)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.form-spoiler{
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 getSpacing('padding-default-horizontal', 'desktop');
    @include breakpoint('tablet') {
        padding: 0 getSpacing('padding-default-horizontal', 'tablet');
    }
    @include breakpoint('mobile') {
        padding: 0 getSpacing('padding-default-horizontal', 'mobile');
    }
}

.white.no-padding {
    padding: 0;
    display: flex;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $container-width; //880px
    margin: 0 auto;
    padding-bottom: 1px;
    margin-bottom: 100px;
    font-size: getFontSize('smallText', 'desktop');
    padding: 0 getSpacing('padding-default-horizontal', 'desktop');

    @include breakpoint('tablet') {
        padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        font-size: getFontSize('smallText', 'tablet');
    }

    @include breakpoint('mobile') {
        padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        font-size: getFontSize('smallText', 'mobile');
    }

    .field-header {
        font-weight: bold;
        text-transform: uppercase;
        margin: 30px 0;
    }

    .section-header {
        font-size: getFontSize('defaultText', 'desktop');
        font-family: getFontFamily('defaultText');
        font-weight: getFontWeight('defaultText');
        text-transform: uppercase;
        text-align: center;
        border-bottom: 2px solid $timberGreen;
        padding-bottom: 12px;
        margin-bottom: 40px;

        @include breakpoint('tablet') {
            font-size: getFontSize('defaultText', 'tablet');
        }

        @include breakpoint('mobile') {
            font-size: getFontSize('defaultText', 'mobile');
        }
    }

    .add-fieldset,
    .remove-fieldset {
        text-decoration: underline;
        cursor: pointer;
        line-height: 40px;
    }

    .add-fieldset {
        max-width: 360px;
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .send {
        max-width: 400px;
        align-self: center;
        margin-top: 50px;
    }

    .field.privacy-policy {
        border: none;
        justify-content: center;
        height: unset;

        .checkbox-wrapper {
            position: relative;


            label {
                display:flex;
                align-items: center;
                cursor: pointer;
                align-content: center;
            }

            input {
                opacity: 0;
                margin-right: 20px;

                &:checked {
                    ~ .checkmark {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            .checkmark {
                position: absolute;
                left: 0;
                height: 20px;
                width: 20px;
                border: 1px solid $timberGreen;

                &:after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    bottom: 5px;
                    left: 5px;
                    background: $timberGreen;
                    opacity: 0;
                }
            }

            &.invalid {
                border-bottom: 2px solid red;
            }
        }
    }
}
</style>

<style lang="scss">
.form {
    .separator.full_width {
        @include breakpoint('tablet') {
            width: 100%;
            margin: 0;
        }
    }
}

.fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;

    &.single {
        .field {
            width: 100%;
        }
    }

    &.finish {
        display: flex;
        flex-direction: column;

        .field {
            width: 100%;
        }
    }

    .centered.field,
    .centered {
        border: none;
        text-align: center;
        display: block;
        width: 100%;
    }
}

.field {
    display: flex;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 15px;
    border-bottom: 1px solid black;
    width: calc(50% - 25px);
    margin-bottom: 10px;

    @include breakpoint('mobile') {
        width: 100%;
    }

    &.invalid {
        border-color: red;
        border-width: 2px;
        margin-bottom: 9px;

        textarea {
            border-width: 2px;
            border-color: red;

            &:focus-visible {
                border-color: red;
                border-width: 2px;
            }
        }

        .select {
            border-color: red;
            border-width: 2px;
        }
    }

    a {
        text-decoration: underline;

        @include breakpoint('desktop') {
            font-size: getFontSize('smallText', 'desktop')
        }

        @include breakpoint('mobile') {
            font-size: getFontSize('smallText', 'mobile')
        }
    }
}

.form-message {
    padding: 100px 0 100px 0;

    img {
        width: 100%;
        max-width: 100px;
        margin: 0 auto;
        display: block;
        padding-bottom: 20px;
    }
}
</style>
